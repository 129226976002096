<template>
  <v-container v-if="vueTocsFilho">
    <PageToolbar voltar :title="$tc('pages.empresa')" icon="settings" dark />

    <v-card class="mt-4">
      <v-container class="py-0">
        <v-row>
          <v-col
            cols="12"
            md="2"
            class="pt-8 lightgray"
            :style="
              $vuetify.breakpoint.mdAndUp
                ? 'border-right: 1px solid rgba(0, 0, 0, 0.12) !important;'
                : ''
            "
          >
            <div class="d-flex justify-center align-center">
              <v-hover v-slot:default="{ hover }">
                <v-avatar
                  size="120"
                  style="box-shadow: 0px 0px 0px 3px rgba(32,140,110,1);"
                >
                  <v-img
                    :src="
                      empresa.empresa_logo
                        ? apiUrl + empresa.empresa_logo
                        : require('@/assets/avatar_placeholder.png')
                    "
                  >
                    <v-expand-transition>
                      <div
                        v-if="hover"
                        class="d-flex transition-fast-in-fast-out darkblue v-card--reveal white--text"
                        style="height: 34px; "
                      >
                        <v-btn
                          depressed
                          text
                          x-small
                          dark
                          class="mt-1"
                          @click="dialogImages = true"
                        >
                          {{ $tc("global.alterar") }}
                        </v-btn>
                      </div>
                    </v-expand-transition>
                  </v-img>
                </v-avatar>
              </v-hover>
            </div>
            <v-card outlined class="mt-6">
              <v-tabs v-model="tab" vertical>
                <v-tab class="caption font-weight-bold justify-start">
                  {{ $tc("global.informacoes") }}
                </v-tab>
                <v-tab
                  class="caption font-weight-bold justify-start"
                  v-if="modulo_whatsapp"
                >
                  Whatsapp
                </v-tab>
                <v-tab
                  class="caption font-weight-bold justify-start"
                  v-if="modulo_whatsapp"
                >
                  Feedback
                </v-tab>
              </v-tabs>
            </v-card>
          </v-col>

          <v-col cols="12" md="10">
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <Campos v-if="empresa" :item="empresa" />
              </v-tab-item>
              <v-tab-item v-if="modulo_whatsapp">
                <Whatsapp />
              </v-tab-item>
              <v-tab-item v-if="modulo_whatsapp">
                <Feedback />
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <SelectImageModal
      :dialogImages.sync="dialogImages"
      origem="tocs"
      :origem_id="empresaId"
    >
      <template v-slot:saveSelected>
        <v-spacer></v-spacer>
        <v-fade-transition>
          <v-btn
            v-if="imagemSelecionada"
            depressed
            dark
            small
            color="toolbar"
            @click="updateAvatar(imagemSelecionada.path_original)"
          >
            {{ $tc("global.salvar") }}
            <v-icon>save</v-icon>
          </v-btn>
        </v-fade-transition>
      </template>
    </SelectImageModal>
  </v-container>
  <NaoAutorizado v-else />
</template>

<script>
import {
  one,
  getImages,
  uploadImage,
  update,
} from "@/services/api/tocs.api.js";
import { mapState, mapGetters } from "vuex";

export default {
  name: "Empresa",
  props: {
    empresaId: {
      type: [String, Number],
      required: true,
    },
  },
  components: {
    Campos: () => import("./components/Campos"),
    Whatsapp: () => import("./components/Whatsapp"),
    Feedback: () => import("./components/Feedback"),
    SelectImageModal: () => import("@/components/SelectImageModal"),
  },
  data() {
    return {
      empresa: {},
      tab: 0,
      loading: false,
      selectedFile: null,
      apiUrl: null,
      images: [],
      avatar: "",
      dialogImages: false,
      carregandoImagem: false,
    };
  },
  computed: {
    ...mapState({
      imagemSelecionada: (state) => state.imagemSelecionada,
    }),
    ...mapState("Tocsconfig", {
      modulo_whatsapp: (state) => state.modulo_whatsapp,
    }),
    ...mapGetters("Componentes", ["getAccess"]),
    vueTocsFilho() {
      return this.getAccess("vueTocsFilho", "visualizar");
    },
    vueEditTocsFilho() {
      return this.getAccess("vueTocsFilho", "editar");
    },
  },
  methods: {
    updateEmpresa() {
      this.$Progress.start();
      this.loading = true;
      update(this.empresaId, this.empresa)
        .then((response) => {
          this.$toast.success(this.$tc("msg.atualizadosucesso"));
          this.$Progress.finish();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
          this.loading = false;
        });
    },
    fetchEmpresa() {
      this.$Progress.start();
      one(this.empresaId)
        .then((response) => {
          this.empresa = response;
          if (
            !this.$store.state.dynamic_crumb ||
            this.$store.state.dynamic_crumb != this.empresa.empresa_nome
          ) {
            this.$store.commit(
              "UPDATE_DYNAMICCRUMB",
              this.empresa.empresa_nome
            );
          }
          this.$Progress.finish();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
          this.loading = false;
        });
    },
    uploadImage(fd) {
      if (fd) {
        this.$Progress.start();
        this.carregandoImagem = true;
        uploadImage(fd)
          .then((response) => {
            if (response.status === 200) {
              this.$toast.success(this.$tc("msg.addsucesso"));
              this.$Progress.finish();
              this.getEmpresaImages();
              this.carregandoImagem = false;
            }
          })
          .catch((error) => {
            console.log(error);
            this.$Progress.fail();
            this.carregandoImagem = false;
          });
      }
    },
    onFileSelected(event, id) {
      this.selectedFile = event.target.files[0];
      const fd = new FormData();
      fd.append("image", this.selectedFile, this.selectedFile.name);
      fd.append("tocs_id", id);
      this.uploadImage(fd);
    },
    getEmpresaImages() {
      getImages(this.empresaId)
        .then((response) => {
          this.images = response;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateAvatar(image) {
      this.empresa.empresa_logo = image;
      this.updateEmpresa(this.empresaId);
      this.dialogImages = false;
    },
  },
  mounted() {
    if (this.vueTocsFilho) {
      this.getEmpresaImages();
      if (process.env.VUE_APP_API_URL) {
        this.apiUrl = process.env.VUE_APP_API_URL;
      }
      this.fetchEmpresa();
    }
  },
};
</script>

<style scoped lang="scss">
.v-card--reveal {
  align-items: flex-start;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
}
</style>
